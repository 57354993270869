<template>
  <div class="page page-xranking-all-matching-offers">
    <PageHeader
      back="/xranking/offers"
      icon="mdi-magnify-scan"
      :title="`Matching - ${name || ''}`"
      class="spt-2"
    >
      <template slot="nav">
        <small><strong>{{ $tc('CV.TITLE_COUNT', count) }}</strong></small>
      </template>
    </PageHeader>

    <Loading :value="loading" color="#f0f0f0" zIndex="1" />

    <div class="scroll">
      <div class="pa-4">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel expand v-model="panel">
            <v-expansion-panel-header>
              <span>{{ $t("JOB.FILTER_TITLE") }}</span>
            </v-expansion-panel-header>

            <v-expansion-panel-content>
              <v-row class="pa-5">
                <v-col xs="12" sm="12" md="6" lg="3">
                  <v-card outlined style="border: 1px solid #e0bc37">
                    <v-card-title>
                      <div style="display: flex; flex-grow: 1; justify-content: space-between">
                        <span>{{ $t("JOB.FIELD_TITLE") }}</span>
                        <div>
                          <v-tooltip bottom transition="none">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn 
                                v-bind="attrs" 
                                v-on="on" 
                                depressed 
                                class="px-1 mr-2" 
                                style="min-width: 36px !important;"  
                                @click="toggleFilter('jobTitle', 'exactTextMatch')"
                                :color="filters.jobTitle.exactTextMatch ? 'accent' : '#f5f5f5' "
                              >
                                <v-icon>mdi-format-letter-case</v-icon>
                              </v-btn>
                            </template>
                            {{ $t('JOB.EXACT_TEXT_MATCH') }}: {{filters.jobTitle.exactTextMatch ? "On" : "Off" }}
                          </v-tooltip>
                          <v-tooltip bottom transition="none">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                depressed
                                class="px-1"
                                style="min-width: 36px !important;"
                                @click="toggleFilter('jobTitle', 'mandatory')"
                                :color="filters.jobTitle.mandatory ? 'error' : '#f5f5f5' "
                              >
                                <v-icon>mdi-asterisk</v-icon>
                              </v-btn>
                            </template>
                            {{ $t('JOB.MANDATORY') }}: {{ filters.jobTitle.mandatory ? "On" : "Off" }}
                          </v-tooltip>
                        </div>
                      </div>
                    </v-card-title>

                    <v-card-text>
                      <v-col>
                        <v-subheader class="px-0">{{ $t("CV_PARSING.WEIGHT") }}</v-subheader>
                        <v-slider
                          hide-details
                          color="accent"
                          :thumb-size="24"
                          :value="filters.jobTitle.weight"
                          @input="(e) => handleSlideChange('jobTitle', e)"
                          thumb-label="always"
                        />
                        <v-text-field
                          v-model="filters.jobTitle.value"
                          dense
                          outlined
                          clearable
                          class="mt-2 page-xranking-all-matching-offers__filter-value"
                          hide-details
                          :label="$t('JOB.FIELD_TITLE')"
                        />
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col xs="12" sm="12" md="6" lg="3">
                  <v-card outlined style="border: 1px solid #e0bc37">
                    <v-card-title>
                      <div style="display: flex; flex-grow: 1; justify-content: space-between">
                        <span>{{ $t('CV_PARSING.SKILLS') }} </span>
                        <div>
                          <v-tooltip bottom transition="none">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn 
                                v-bind="attrs" 
                                v-on="on" 
                                depressed 
                                class="px-1 mr-2" 
                                style="min-width: 36px !important;"
                                @click="toggleFilter('skills', 'exactTextMatch')"
                                :color="filters.skills.exactTextMatch ? 'accent' : '#f5f5f5' "
                              >
                                <v-icon>mdi-format-letter-case</v-icon>
                              </v-btn>
                            </template>
                            {{ $t('JOB.EXACT_TEXT_MATCH') }}: {{ filters.skills.exactTextMatch ? "On" : "Off" }}
                          </v-tooltip>
                          <v-tooltip bottom transition="none">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn  v-bind="attrs"
                                v-on="on"
                                depressed
                                class="px-1"
                                style="min-width: 36px !important;" 
                                @click="toggleFilter('skills', 'mandatory')"
                                :color="filters.skills.mandatory ? 'error' : '#f5f5f5' "
                              >
                                <v-icon>mdi-asterisk</v-icon>
                              </v-btn>
                            </template>
                            {{ $t('JOB.MANDATORY') }}: {{ filters.skills.mandatory ? "On" : "Off" }}
                          </v-tooltip>
                        </div>
                      </div>
                    </v-card-title>
                    <v-card-text>
                      <v-col>
                        <v-subheader class="px-0">{{ $t("CV_PARSING.WEIGHT") }}</v-subheader>
                        <v-slider
                          hide-details
                          color="accent"
                          thumb-label="always"
                          :thumb-size="24"
                          :value="filters.skills.weight"
                          @input="(e) => handleSlideChange('skills', e)"      
                        />
                        <v-combobox
                          v-model="filters.skills.value"
                          outlined
                          clearable
                          multiple
                          small-chips
                          dense
                          class="mt-2"
                          hide-details
                          :append-icon="null"
                          :label="$t('CV_PARSING.SKILLS')"
                          :items="filters.skills.value"
                        >
                          <template v-slot:selection="{ item }">
                            <v-chip small class="page-xranking-all-matching-offers__filter-value">
                              <span>{{ item }}</span>
                            </v-chip>
                          </template>
                        </v-combobox> 
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col xs="12" sm="12" md="6" lg="3">
                  <v-card outlined style="border: 1px solid #e0bc37">
                    <v-card-title>
                      <div style="display: flex; flex-grow: 1; justify-content: space-between">
                        <span>{{ $t('JOB.FIELD_SECTOR') }}</span>
                        <div>
                          <v-tooltip bottom transition="none">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                depressed
                                class="px-1"
                                style="min-width: 36px !important;" 
                                @click="toggleFilter('professionalCategory', 'mandatory')"
                                :color="filters.professionalCategory.mandatory ? 'error' : '#f5f5f5' "
                              >
                                  <v-icon>mdi-asterisk</v-icon>
                              </v-btn>
                            </template>
                            {{ $t('JOB.MANDATORY') }}: {{ filters.professionalCategory.mandatory ? "On" : "Off" }}
                          </v-tooltip>
                        </div>
                      </div>
                    </v-card-title>

                    <v-card-text>
                      <v-col>
                        <v-subheader class="px-0">{{ $t("CV_PARSING.WEIGHT") }}</v-subheader>
                        <v-slider
                          hide-details
                          color="accent"
                          thumb-label="always"
                          :thumb-size="24"
                          :value="filters.professionalCategory.weight"
                          @input="(e) => handleSlideChange('professionalCategory', e)"
                        />
                        <v-autocomplete
                          v-model="filters.professionalCategory.value"
                          outlined
                          multiple
                          dense
                          small-chip
                          clearable
                          hide-details
                          class="mt-2"
                          item-text="item"
                          :items="rome"
                          :label="$t('JOB.FIELD_SECTOR')"
                        >
                          <template v-slot:selection="{ item }">
                            <v-chip
                              small
                              style="max-width: 150px !important"
                              class="page-xranking-all-matching-offers__filter-value"
                            >
                              <span>{{ item }}</span>
                            </v-chip>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col xs="12" sm="12" md="6" lg="3">
                  <v-card outlined style="border: 1px solid #e0bc37">
                    <v-card-title>
                      <div style="display: flex; flex-grow: 1; justify-content: space-between">
                        <span>{{ $t('JOB.FIELD_DIPLOMAS') }}</span>
                        <div>
                          <v-tooltip bottom transition="none">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn  v-bind="attrs"
                                v-on="on"
                                depressed
                                class="px-1"
                                style="min-width: 36px !important;" 
                                @click="toggleFilter('educationLvl', 'mandatory')"
                                :color="filters.educationLvl.mandatory ? 'error' : '#f5f5f5' "
                              >
                                  <v-icon>mdi-asterisk</v-icon>
                              </v-btn>
                            </template>
                            {{ $t('JOB.MANDATORY') }}: {{ filters.educationLvl.mandatory ? "On" : "Off" }}
                          </v-tooltip>
                        </div>
                      </div>
                    </v-card-title>

                    <v-card-text>
                      <v-col>
                        <v-subheader class="px-0">{{ $t("CV_PARSING.WEIGHT") }}</v-subheader>
                        <v-slider
                          hide-details
                          color="accent"
                          thumb-label="always"
                          :thumb-size="24"
                          :value="filters.educationLvl.weight"
                          @input="(e) => handleSlideChange('educationLvl', e)"
                        />
                        <v-autocomplete 
                          multiple 
                          small-chips 
                          dense 
                          class="mt-2" 
                          hide-details 
                          :label="$t('JOB.FIELD_DIPLOMAS')" 
                          :items="educationLevels" 
                          deletable-chips
                          v-model="filters.educationLvl.value"
                          item-text="key"
                          item-value="value"
                          return-object
                          outlined 
                          clearable
                        >
                          <template v-slot:selection="{ item }">
                            <v-chip small class="page-xranking-all-matching-offers__filter-value">
                              <span>{{ item.key }}</span>
                            </v-chip>
                          </template>
                        </v-autocomplete> 
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col xs="12" sm="12" md="6" lg="4" >
                  <v-card outlined style="border: 1px solid #e0bc37">
                    <v-card-title>
                      <div style="display: flex; flex-grow: 1; justify-content: space-between">
                        <span>{{ $t('CV_PARSING.LANGUAGES') }}</span>
                        <div>
                          <v-tooltip bottom transition="none">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                depressed
                                class="px-1"
                                style="min-width: 36px !important;" 
                                @click="toggleFilter('languages', 'mandatory')"
                                :color="filters.languages.mandatory ? 'error' : '#f5f5f5' "
                              >
                                <v-icon>mdi-asterisk</v-icon>
                              </v-btn>
                            </template>
                            {{ $t('JOB.MANDATORY') }}: {{ filters.languages.mandatory ? "On" : "Off" }}
                          </v-tooltip>
                        </div>
                      </div>
                    </v-card-title>

                    <v-card-text>
                      <v-col>
                        <v-subheader class="px-0">{{$t("CV_PARSING.WEIGHT")}}</v-subheader>
                        <v-slider
                          hide-details
                          color="accent"
                          :thumb-size="24"
                          :value="filters.languages.weight"
                          @input="(e) => handleSlideChange('languages', e)"
                          thumb-label="always"
                        />
                        <v-autocomplete 
                          multiple 
                          :search-input.sync="languageInput"
                          small-chips 
                          dense 
                          class="mt-2" 
                          hide-details 
                          :label="$t('CV_PARSING.LANGUAGES')" 
                          :items="languages" 
                          deletable-chips
                          v-model="filters.languages.value"
                          item-text="key"
                          item-value="value"
                          return-object
                          outlined 
                          clearable
                          @change="languageInput = ''"
                        >
                          <template v-slot:selection="{ item }">
                            <v-chip small class="page-xranking-all-matching-offers__filter-value">
                              <span>{{ item.key }}</span>
                            </v-chip>
                          </template>
                        </v-autocomplete> 
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col  xs="12" sm="12" md="6" lg="3" >
                  <v-card
                    outlined
                    style="display: flex; flex-direction: column; border: 1px solid #e0bc37"
                    class="height-100"
                  >
                    <v-card-title>
                      <div style="display: flex; flex-grow: 1; justify-content: space-between">
                        <span>{{ $t('JOB.FIELD_TAGS') }}</span>
                      </div>
                    </v-card-title>
                    <v-card-text style="display: flex; flex-grow: 1; align-items: flex-end">
                      <v-col>
                        <v-combobox
                          v-model="filters.keywords.value"
                          multiple
                          small-chips
                          deletable-chips
                          dense
                          class="mt-2"
                          hide-details
                          return-object
                          outlined
                          clearable
                          :append-icon="null"
                          :label="$t('JOB.FIELD_TAGS')"
                          :items="filters.keywords.value"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip
                              v-if="index < 2"
                              small
                              class="page-xranking-all-matching-offers__filter-value"
                            >
                              <span>{{ item }}</span>
                            </v-chip>
                            <span
                              v-if="index === 2"
                              class="grey--text text-caption"
                            >
                              +{{ filters.keywords.value.length - 2 }} {{ $tc('COMMON.OTHERS', filters.keywords.value.length - 2) }}
                            </span>
                          </template>
                        </v-combobox> 
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col xs="12" sm="12" md="12" lg="5" >
                  <v-card
                    outlined
                    style="display: flex; flex-direction: column; border: 1px solid #e0bc37"
                    class="height-100"
                  >
                    <v-card-title>
                      <div style="display: flex; flex-grow: 1; justify-content: space-between">
                        <span>{{ $t('CV_PARSING.CITY') }}</span>
                      </div>
                    </v-card-title>

                    <v-card-text style="display: flex; flex-grow: 1; align-items: flex-end">
                      <v-col cols="8" >
                        <v-autocomplete
                          v-model="selectedCity"
                          :items="cities"
                          :loading="false"
                          :search-input.sync="cityInput"
                          dense 
                          class="mt-2"
                          outlined
                          hide-selected
                          hide-details
                          label="Search a city"
                          :filter="() => true"
                        >
                          <template v-slot:selection="{index, item}">
                            {{ item.name }}, {{ item.countrySubdivision }}, {{ item.country }}
                          </template>
                          <template v-slot:item="{index, item}" >
                            {{ item.name }}, {{ item.countrySubdivision }}, {{ item.country }}
                          </template>
                        </v-autocomplete> 
                      </v-col>
                      <v-col cols="4" class="pl-0">
                        <v-text-field
                          type="number"
                          dense 
                          class="mt-2" 
                          hide-details 
                          label="Radius in km" 
                          v-model="filters.radius.value"
                          outlined 
                        ></v-text-field> 
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row justify="end" class="pa-8 pt-0">
                <div>
                  <v-btn color="accent" @click="searchItems(false)">
                    {{ $t('COMMON.SEARCH') }}
                  </v-btn>
                </div>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <List
        v-if="jobMatch.length"
        noButton
        actions
        :items="currentPageData"
        :count="count"
        :offset="offset"
        :limit="limit"
        :loading="loading"
        :searchable="false"
        :selectable="false"
        :pagination="true"
        @page="changePage"
        @reload="validateRaOrg"
        @click="goToItem"
        @backToList="backToList"
      >
        <template v-slot:header>
          <div class="page page-xranking-all-matching-offers__list-header pr-4">
            <v-list-item-content class="page-xranking-all-matching-offers__item-content-description">
              <v-label>{{ $t('COMMON.LABELS.TITLE') }}</v-label>
            </v-list-item-content>

            <v-list-item-content class="page-xranking-all-matching-offers__item-content-office mr-3">
              <v-label>{{ $t('COMMON.LABELS.OFFICE') }}</v-label>
            </v-list-item-content>

            <v-list-item-content class="page-xranking-all-matching-offers__item-content-score">
              <v-label>{{ $t('COMMON.LABELS.SCORE') }}</v-label>
            </v-list-item-content>

            <v-list-item-action class="page-xranking-all-matching-offers__header-actions-label ml-0">
              <v-list-item-content class="ml-4">
                <v-label>{{ $t('COMMON.LABELS.CREATED_AT') }}</v-label>
              </v-list-item-content>

              <v-list-item-content class="ml-4" :style="{width: '80px'}">
                <v-label>
                  {{ $t('COMMON.LABELS.ACTIONS') }}
                </v-label>
              </v-list-item-content>
            </v-list-item-action>
          </div>
        </template>

        <template v-slot:item-list="item">
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              <strong>{{ item.applicant_id }}</strong>
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-content class="page-xranking-all-matching-offers__item-content-office mr-3">
            <v-list-item-title class="primary--text">
              <strong class="page-xranking-all-matching-cvs__item-office">{{ item.source_name }}</strong>
            </v-list-item-title>
          </v-list-item-content>
            
          <v-list-item-action
            class="page-xranking-all-matching-offers__item-content-score mr-3 date"
            style="width:auto"
          >
            <div class="d-flex align-center">
              <v-menu
                open-on-hover
                left
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span
                      v-for="(_, index) in 5"
                      :key="`block-${index}`"
                      class="page-xranking-all-matching-offers__score-item"
                      :style="backgroundScore(index, item.score)"
                    />
                  </div>
                </template>

                <v-list
                  style="max-width: 1000px"
                >
                  <v-list-item-title class="px-4 py-2">{{$t('JOB.TOOLTIP.MATCHING_CRITERIA')}}</v-list-item-title>
                  <template v-for="(criteria, index) in item.matchingCriterias">
                    <v-list-item :key="index">
                      <v-list-item-action style="width: fit-content; margin-right: 10px">
                        <v-icon color='success' v-if="criteria.match">mdi-check-circle-outline</v-icon>
                        <v-icon color='error' v-else>mdi-close-circle-outline</v-icon>
                      </v-list-item-action>
                      <v-list-item-title>
                        <small>{{ criteria.label }}</small>
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider v-if="index !== item.matchingCriterias.length - 1" :key="'div' + index" />
                  </template>
                </v-list>

              </v-menu>
            </div>
          </v-list-item-action>

          <v-list-item-action class="ml-3 date">{{ $moment(item.created_at).fromNow() }}</v-list-item-action>

          <v-list-item-action>
            <div>
              <v-tooltip bottom transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    depressed
                    color="accent"
                    class="px-1"
                    style="min-width: 36px !important"
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="goToMatching(item)"
                  >
                    <v-icon>mdi-magnify-scan</v-icon>
                  </v-btn>
                </template>
                {{ $t('COMMON.MATCHING') }}
              </v-tooltip>
              <v-tooltip bottom transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    class="ml-2"
                    @click.stop="loadFileDetails(item.id)"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
              {{ $t('COMMON.DOWNLOAD') }}
              </v-tooltip>
            </div>
          </v-list-item-action>
        </template>
      </List>
      <div
        v-if="!items.length && noData"
        class="page-xranking-all-matching-offers__no-data mt-10"
      >
        {{ $t("COMMON.NO_DATA") }}
      </div>

      <!--ACTIONS PANEL -->
      <div
        v-if="!items.length && noData"
        class="d-flex justify-start mt-16 pl-14 pr-10"
      >
        <v-btn icon x-large @click="backToList">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import Loading from '@/components/Loading'
import PageHeader from '@/components/PageHeader'
import List from '@/components/List'
import langs from '@/data/fr_lang.json'
import rome_flat from '@/data/rome_taxo_flat.js'
import education_levels from '@/data/education_levels.json'

export default {
  name: 'PageXrankingAllMatchingOffers',
  components: {
    Loading,
    PageHeader,
    List,
    langs,
  },
  data: (_this) => ({
    items: [],
    firstLoad: true,
    panel: false,
    offer: null,
    name: null,
    colors: ['#FF4E11', '#FF8E15', '#FAB733', '#ACB334', '#69B34C'],
    color: 'rgba(0,0,0, .08)',
    loading: false,
    count: 0,
    offset: 0,
    limit: 10,
    page: 1,
    tags: [],
    languageInput: '',
    selectedCity: [],
    cityInput: '',
    radius: '',
    cities: [],
    isLoadingCityInput: false,
    filters: {
      jobTitle: {
        value: '',
        exactTextMatch: false,
        mandatory: false,
        weight: 20,
      },
      skills: {
        value: [],
        exactTextMatch: false,
        mandatory: false,
        weight: 20,
      },
      educationLvl: {
        value: [],
        exactTextMatch: true,
        mandatory: false,
        weight: 20,
      },
      professionalCategory: {
        value: [],
        exactTextMatch: true,
        mandatory: false,
        weight: 20,
      },
      languages: {
        value: [],
        exactTextMatch: true,
        mandatory: false,
        weight: 20,
      }, 
      keywords: {
        value: [],
        exactTextMatch: true,
        mandatory: false,
        weight: 20,
      },
      city: {
        value: '',
      },
      radius: {
        value: '',
      },
    },
    languages: langs,
    rome: rome_flat,
    educationLevels: education_levels,
    noData: false,
  }),
  watch: {
    offer(n) {
      this.name = n.description[0].occupations.length ? n.description[0].occupations[0].plaintext : n.title.plaintext
    },

    cityInput(n) {
       if (n && n !== this.filters.city.value) {
         this.loadCity(n)
       }
    },

    selectedCity(n) {
      this.filters.city.value = n;
    }
  },
  computed: {
    ...mapState('user', {
      userOfficeId: state => state.user.office_id,
    }),

    ...mapGetters({
      token: 'user/getToken',
      jobMatch: 'job/getJobMatch',
    }),

    offerId() {
      return this.$route.params.id;
    },

    currentPageData() {
      const start = (this.page - 1) * this.limit;
      const end = start + this.limit;
      const cloneJobMatch = JSON.parse(JSON.stringify(this.jobMatch))
      return cloneJobMatch.slice(start, end);
    },
  },
  mounted() {
    this.validateRaOrg()
  },
  methods: {
    ...mapActions({
      addJobMatch: 'job/addJobMatch',
      addCVMatch: 'cv/addCVMatch',
    }),

    handleSlideChange (sliderIndex, value) {
      const indexOfFilters = ['jobTitle', 'skills', 'educationLvl', 'professionalCategory', 'languages']
      const { jobTitle, skills, educationLvl, professionalCategory, languages } = this.filters
      let slidersWithoutMovingOne = [jobTitle.weight, skills.weight, educationLvl.weight, professionalCategory.weight, languages.weight]

      slidersWithoutMovingOne.splice(indexOfFilters.indexOf(sliderIndex), 1)

      const sum = slidersWithoutMovingOne.reduce((a, b) => a + b, 0) + value
      const rest = 100 - sum
      const nonZeroSliders = slidersWithoutMovingOne.filter(slider => slider > 0).length

      if (rest < 0) {
        slidersWithoutMovingOne = slidersWithoutMovingOne.map(slider => {
          return slider > 0
            ? this.clampSliders(slider + Math.floor(rest / nonZeroSliders))
            : slider
        })
      }

      slidersWithoutMovingOne.splice(indexOfFilters.indexOf(sliderIndex), 0, value)
      slidersWithoutMovingOne.forEach((slidersValue, i) => this.filters[indexOfFilters[i]].weight = slidersValue)

      if (slidersWithoutMovingOne.reduce((a, b) => a + b, 0) <= 100) return

      this.handleSlideChange(sliderIndex, value)
      return
    },

    toggleFilter (category, filter) {
      const oldValue = this.filters[category][filter]
      this.filters[category][filter] = !oldValue
    },

    goToItem (item) {
      item = item ? item : this.selected[0]
      let route = this.$router.resolve(`/xranking/cvs/${item.id}`)
      window.open(route.href, '_blank')
    },

    selectItem (item) {
      for (let i=0, len = this.selected.length; i < len; i++) {
        if (this.selected[i].id === item.id) return this.selected.splice(i,1)
      }

      return this.selected.push(item)
    },

    selectItems (arr) {
      this.selected = arr
    },

    changePage (page) { 
      this.page = page
      this.offset = page - 1
      this.searchItems(true)
    },

    validateRaOrg() {
      this.$services.xusers_ra.organization.describe()
        .then((response) => {
          if (response.data.organization.id !== this.userOfficeId) {
            this.$store.dispatch('messenger/add', {type: 'warning', code: 'XRANKING.MISSING_OFFICE_ORG_MAPPING',
              message: `Office ${this.userOfficeId} does not have a corresponding xusers organization mapped`, time: 5000 })
          } else {
            //load offer info
            this.loadOffer()
          }
        })
        .catch(e => {
          let message = `Error validating organization for office ${this.userOfficeId}`
          if (e.error && e.error.code && e.error.message) {
            message = `${e.error.code} - ${e.error.message}`
          }
          this.$store.dispatch('messenger/add', {type: 'error', code: 'XRANKING.ORGANIZATION_ERROR',
            message, time: 5000 })
        })
    },

    clampSliders (num, min = 0, max = 100) {
      return Math.min(Math.max(num, min), max)
    },


    formatFilters () {
      let tags = []

      if (this.filters.jobTitle.value?.length) {
        tags.push({
          category: 'job_title_plaintext',
          value: {
            id: '',
            label: this.filters.jobTitle.value,
          },
          isMandatory: this.filters.jobTitle.mandatory,
          exactMatch: this.filters.jobTitle.exactTextMatch,
          boost: this.clampSliders(this.filters.jobTitle.weight),
        })
      }

      this.filters.skills.value.forEach((skill) => {
        tags.push({
          category: 'skill_plaintext',
          value: {
            id: '',
            label: skill,
          },
          isMandatory: this.filters.skills.mandatory,
          exactMatch: this.filters.skills.exactTextMatch,
          boost: this.clampSliders(this.filters.skills.weight),
        })
      })

       this.filters.professionalCategory.value.forEach((category) => {
        let categoryArray = category.split('-')
        const id = categoryArray[0]
        const label = categoryArray.splice(1, categoryArray.length - 1).join('-')

        tags.push({
          category: 'professional_category',
          value: {
            id: id,
            label: label,
          },
          isMandatory: this.filters.professionalCategory.mandatory,
          exactMatch: true,
          boost: this.clampSliders(this.filters.professionalCategory.weight),
        })
      })

       this.filters.educationLvl.value.forEach((educationLvl) => {
        tags.push({
          category: 'education_level',
          value: {
            id: educationLvl.value,
            label: educationLvl.key,
          },
          isMandatory: this.filters.educationLvl.mandatory,
          exactMatch: this.filters.educationLvl.exactTextMatch,
          boost: this.clampSliders(this.filters.educationLvl.weight),
        })
      })

       this.filters.languages.value.forEach((language) => {
        tags.push({
          category: 'language',
          value: {
            id: language.value,
            label: language.key,
          },
          isMandatory: this.filters.languages.mandatory,
          exactMatch:this.filters.languages.exactTextMatch,
          boost: this.clampSliders(this.filters.languages.weight),
        })
      })

       this.filters.keywords.value.forEach((keyword) => {
        tags.push({
          category: 'keyword',
          value: {
            id: '',
            label: keyword,
          },
          isMandatory: this.filters.keywords.mandatory,
          exactMatch: this.filters.keywords.exactTextMatch,
          boost: 0,
        })
      })

      return tags
    },

    importTags (tags) {
      let tmpSkills = []
      let tmpKeywords = []
      let tmpCategories = []
      let tmpEducationLvl = []
      let tmpLanguages = []

      tags.forEach((tag) => {
        // Without the weight tag set to 20 here, the request sent to ranking.xtramile.tech
        // will have weight: null, triggerring a 500 Internal Server Error misreported as a CORS issue in Chrome
        if (tag.category === 'job_title_plaintext') {
          this.filters.jobTitle = {
            value : tag.value.label,
            exactTextMatch: tag.exactMatch,
            mandatory: tag.isMandatory,
            weight: 20,
          }
        }
        if (tag.category === 'professional_category') {
          tmpCategories.push(`${tag.value.id}-${tag.value.label}`)
          this.filters.professionalCategory.mandatory = tag.isMandatory
        }
        if (tag.category === 'skill_plaintext') {
          tmpSkills.push(tag.value.label)
        }
        if (tag.category === 'education_level') {
          tmpEducationLvl.push({
            key: tag.value.label,
            value: parseInt(tag.value.id),
          })
        }
        if (tag.category === 'language') {
          tmpLanguages.push({ key: tag.value.label, value: tag.value.id })
        }
        if (tag.category === 'keywords') {
          tmpKeywords.push(tag.value.label)
        }
      })

      this.filters.professionalCategory.value = tmpCategories
      this.filters.keywords.value = tmpKeywords
      this.filters.skills.value = tmpSkills
      this.filters.educationLvl.value = tmpEducationLvl
      this.filters.languages.value = tmpLanguages
    },

    loadOffer () {
      this.$services.xranking_ra
        .v2_offers.getById(this.offerId)
        .then(({ data }) => {
          const { job } = data
          this.offer = job
          this.searchItems()
        })
    },

    backgroundScore (i, score) {
      return {
        backgroundColor: i < Math.round(score / 20) ? this.colors[i] : this.color,
      }
    },
  
    // TODO : Query to retrieve matched CVS (https://ranking.xtramile.io/api/v2/job/match?vacancyReference)
    // Without filters for the moment
    searchItems(changePage) {
      // if (!changePage) {
      // }
      this.loading = true
      const tags = this.formatFilters()
      // TODO: Check why the new Xtramile offer has source.id a string instead of an id, like the others
      // the vacancyReference value is the source.id of the offer
      const filters = { tags }
      let cityURL = ''
      if (this.filters.city.value && this.filters.radius.value) {
         cityURL = `&location=${this.filters.city.value.lat}|${this.filters.city.value.lon}|${this.filters.radius.value}`
      }

      this.$services.xmatching_ra.v2_job.match(this.offer.id, cityURL, filters)
        .then(({ data: { data: { applicants, tags } }}) => {
          this.addJobMatch(applicants || [])
          this.items = applicants
          this.count = applicants.length

          if (this.firstLoad) {
            this.tags = tags
            this.firstLoad = false
            this.importTags(tags)
          }
        })
        .finally(() => {
          this.loading = false
          this.noData = true
        })

      this.offset = this.limit * this.page - 1
    },

    loadCity(n) {
      if (!n || !n.length) return
      this.isLoadingCityInput = true
      this.$services.xranking_ra.city.get(n)
        .then((response) => {
          let data = []
          data = response.data;
          this.cities = data
        })
        .catch((e) => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
        .finally(() => {
          this.isLoadingCityInput = false
        })
    },

    backToList() {
      this.$router.push('/xranking/offers/')
    },

    goToMatching (item) {
      const { id } = item ? item : this.selected[0]
      this.addCVMatch([])
      this.$router.push(`/xranking/matching/cvs/${id}`)
    },

    async loadFileDetails(fileId) {
      let data

      try{
        const { data: responseData } = await this.$services.xxlsfiles_ra.file.detailes(fileId)
        data = responseData
      } catch({ error: { message, details: { Error }}}) {
        const msg = `${message}. ${Error || ''}`
        this.$store.dispatch('messenger/add', {type: 'error', code: 'XRANKING.SERVER_ERROR',
          message: msg, time: 5000 })
      }

      if (data.status === 'processed') {
        const link = document.createElement('a')
        const downloadFileName = `${data.original_filename.split('.').shift()}.xlsx`
        const windowUrl = window.URL || window.webkitURL
        const req = new window.XMLHttpRequest()
        req.responseType = 'blob'
        req.open(
          'GET', `${process.env.VUE_APP_KONG_URL}/xranking/v2/cvs/${fileId}?exportFormat=xlsx&exportLang=${this.$t('COMMON.ANOTHER.LANG')}`
        )
        req.setRequestHeader('authorization', `Bearer ${this.token}`)
        req.onload = function onload() {
          const blob = req.response // Note: not oReq.responseText
          const url = windowUrl.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', downloadFileName)
          link.click()
          windowUrl.revokeObjectURL(url)
        }
        req.send()
      }
    },
  },
}
</script>

<style lang="scss">
.page-xranking-all-matching-offers {
  position: relative;

  .v-btn.v-btn--icon.v-btn--round.theme--light.v-size--x-large {
    background-color: $color-dark-blue;
    color: $color-white;

    &:hover {
      opacity: 0.6;
    }
  }

  .list-actions {
    margin-bottom: 85px;
    
    .v-btn--icon,
    .theme--light.v-btn.v-btn--has-bg {
      background-color: $color-dark-blue;
      color: $color-white;

      &:hover {
        background-color: $color-white;
        color: $color-dark-blue;
      }
    }
  }
}

.page-xranking-all-matching-offers__list-header {
  display: flex;
  flex-direction: row;
}

.page-xranking-all-matching-offers__filter-value {
  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  input {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.page-xranking-all-matching-offers__header-actions-label {
  display: flex;
  flex-direction: row;
}

.page-xranking-all-matching-offers__item-description,
.page-xranking-all-matching-offers__item-office,
.page-xranking-all-matching-offers__item-date {
  white-space: pre-wrap;
}

.page-xranking-all-matching-offers__item-content-office {
  flex: unset;
  min-width: 365px;
  max-width: 365px;
}

.page-xranking-all-matching-offers__item-content-score {
  flex: unset;
  min-width: 165px;
  max-width: 165px;
}

.page-xranking-all-matching-offers__no-data {
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: $color-dark-blue;
}

.page-xranking-all-matching-offers__score-item {
  min-width: 30px;
  min-height: 15px;
  margin: 1px;

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
</style>

